import styled from "styled-components"
import { sm, md, lg, xl } from "../../utils/breakpoints"

export const Contact = styled.div`
  padding: 3rem 0rem;
  background-color: #fbfbfb;
  @media ${md} {
    padding: 6rem 0
  }
`
export const ContactInner = styled.div`
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  flex-direction:column;
  
  i {
    color: #f9a21a;
    font-size: 2.4rem;
    margin-right: 1rem;
  }
  
  a {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.6rem; 
    color: #5e5e5e;
    margin: 1.2rem 0;
  }

  @media ${md} {
    a {
      font-size: 2.2rem;
    }
    i {
      font-size: 3.4rem;
    }
  }

  @media ${md} {
    flex-direction: row;
  }
`

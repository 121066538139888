import React, { Fragment } from "react"
import SliceMachine from "../../slices/SliceMachine"
import Container from "../common/Container"
import { Heading, Item } from "./styles"

const SupportArticle = ({ data }) => {
  const { title, body } = data.data

  return (
    <Item>
      <Heading>
        <Container readable>
          <h1>{title.text}</h1>
        </Container>
      </Heading>
      <SliceMachine body={body} />
    </Item>
  )
}
export default SupportArticle

import styled from "styled-components"
import { sm, md, lg, xl } from "../../utils/breakpoints"

export const TopBar = styled.div`
  background-color: #fbfbfb;
  padding: 10px 0rem;
  @media ${md} {
    padding: 10px 2rem;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export const Breadcrumbs = styled.ul`
  list-style: none;
  display: flex;
  font-size: 17px;
  color: #333;
  padding: 0;
  ol {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media ${md} {
      flex-direction: row;
    }
    li {
      font-weight: 700;
      color: #25c1a9;
      margin-bottom: 10px;
      font-size: 1.5rem;
      
      i {
        display: none;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: inherit;
        @media ${md} {
          display: inline;
        }
      }

      &:last-of-type {
        i {
          display: none;
        }
      }
      @media ${md} {
        margin-bottom: 0;
        font-size: 1.8rem;
      }
    }
  }
  div {
    display: flex;
  }

  i {
    color: #888;
    margin: 4px 0 0 0;
  }
  a {
    color: #888;
    text-decoration: none;
    padding-left: 0px;
    font-weight: 700;
    margin-right: 0px;
    @media ${md} {
      margin-right: 1rem;
    }
    &:hover,
    &:active {
      color: #25c1a9;
    }
  }
  span {
    margin-left: 1rem;
  }
`

import React from "react"
import styled from "styled-components"
import Container from "../../components/common/Container"
import { md } from "../../utils/breakpoints"

const SupportContentWrapper = ({ children }) => {
  return (
    <Section>
      <Container>
        <TopGrid>
          {children}
        </TopGrid>
      </Container>
    </Section>
  )
}

export default SupportContentWrapper

const Section = styled.div`
  @media ${md} {
    padding: 0 2rem;
  }
`

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding-bottom: 0;
  @media ${md} {
    grid-template-columns: 1fr 3fr;
    padding: 4rem 0;
  }
`

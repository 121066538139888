import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { md, lg } from "../utils/breakpoints"

import Layout from "../components/Layout"
import SupportContentWrapper from "../containers/SupportContent"
import Meta from "../components/Meta"
import SupportCategorySidebar from "../components/SupportCategorySidebar"
import SupportArticle from "../components/SupportArticle"
import SupportContact from "../components/SupportContact"
import SupportBreadcrumbs from "../components/SupportBreadcrumbs"

const Template = ({ data, pageContext, location }) => {
  const { title, articles } = data.CategoryData.data
  const { meta_title, meta_description } = data.ArticleData.data
  const bcrumb = [
    { name: "Support", link: "/support/" },
    { name: title.text, link: `/support/category/${data.CategoryData.uid}/` },
    { name: data.ArticleData.data.title.text, link: null }
  ]

  return (
    <Layout location={location} prismicLayout={data.prismicLayout} hideSalesCTA>
      <Meta
        title={meta_title ? meta_title : "Yarno Support"}
        description={meta_description ? meta_description : "Yarno Support"}
        canonical={location.href}
      />
      <SupportBreadcrumbs bcrumb={bcrumb} />
      <SupportContentWrapper>
        <SupportCategorySidebar
          title={title.text}
          articles={articles}
          categoryUrl={data.CategoryData.uid}
        />
        <Item>
          <SupportArticle data={data.ArticleData} />
        </Item>
      </SupportContentWrapper>
      <SupportContact />
    </Layout>
  )
}

export default Template

export const query = graphql`
  query SupportCategoryArticleQuery($id: String, $cid: String) {
    ...PrismicLayout
    CategoryData: prismicSupportCategory(id: { eq: $cid }) {
      ...SupportCategory
    }
    ArticleData: prismicSupportArticle(id: { eq: $id }) {
      ...SupportArticle
    }
  }
`

const Item = styled.div`
  margin-bottom: 2rem;
  padding: 0;
`

import React, { Fragment } from "react"
import { Link } from "gatsby"

import Container from "../common/Container"
import { TopBar, Breadcrumbs } from "./styles"

const SupportBreadcrumbs = ({ bcrumb }) => {
  return (
    <TopBar>
      <Container>
        <div className="row">
          <Breadcrumbs>
            <i className="fa fa-lifebuoy" />
            <ol vocab="https://schema.org/" typeof="BreadcrumbList">
              {bcrumb.map((item, index) => {
                if (item.link === null) {
                  return (
                    <li key={item.name} property="itemListElement" typeof="ListItem">
                      <span property="name">{item.name}</span>
                      <meta property="position" content={index + 1} />
                    </li>
                  )
                }

                return (
                  <li key={item.name} property="itemListElement" typeof="ListItem">
                    <Link property="item" typeof="WebPage" to={item.link}>
                      {item.itemtype && (
                        <span itemprop="name" property="name">
                          {item.name}
                        </span>
                      )}
                      {!item.itemtype && (
                        <span property="name">{item.name}</span>
                      )}
                    </Link>
                    <i className="fa fa-chevron-right" />
                    <meta property="position" content={index + 1} />
                  </li>
                )
              })}
            </ol>
          </Breadcrumbs>
        </div>
      </Container>
    </TopBar>
  )
}

export default SupportBreadcrumbs

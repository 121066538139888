import styled from "styled-components"
import { md } from "../../utils/breakpoints"

export const Heading = styled.div`
  margin-bottom: 2.4rem;
  h1 {
    font-weight: 700 !important;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
`

export const Item = styled.div`
  margin-left: -2rem;
  margin-right: -2rem;
  max-width: 80rem;

  ul, ol {
    padding-left: 2rem;

    li {
      margin-bottom: 0.5rem;
      padding-left: 0.5rem;
      a {
        text-decoration: none;
      }
    }
  }

  img {
    padding: 0 2rem;
  }

  @media ${md} {
    h2 {
      font-size: 3.125rem;
    }

    h3 {
      font-size: 2.501rem;
    }

    h4 {
      font-size: 2rem;
    }

    h5 {
      font-size: 1.2rem;
    }
  }
`

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { md } from "../../utils/breakpoints"

const SupportCategorySidebar = ({ title, articles, categoryUrl, larger }) => {
  return (
    <Sidebar larger={larger}>
      <h3>{title}</h3>
      {articles && articles.length > 0 && (
        <ul>
          {articles.map(
            ({ article }) =>
              article.document &&
              article.document.uid && (
                <li key={article.document.uid}>
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 3.5H11M11 3.5L8.5 1M11 3.5L8.5 6"
                      stroke="#F9A21A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Link
                    to={`/support/category/${categoryUrl}/${article.document.uid}`}>
                    {article.document.data.title.text}
                  </Link>
                </li>
              )
          )}
        </ul>
      )}
    </Sidebar>
  )
}

export default SupportCategorySidebar

const Sidebar = styled.div`
  margin-bottom: 2rem;
  padding: 0;
  h3 {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    color: #25c1a9;
    @media ${md} {
      margin-top: 2rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  li {
    list-style: none;
    font-weight: 500;
    font-family: Raleway;
    line-height: 22px;
    color: #4a4a4a;
    margin-bottom: 1rem;
    a {
      font-size: ${({ larger }) => (larger ? "1.7rem" : "1.5rem")};
      text-decoration: none;
      color: #4a4a4a;
    }
    svg {
      margin-right: 16px;
      float: left;
      height: 25px;
    }
    @media ${md} {
      a {
        font-size: ${({ larger }) => (larger ? "1.8rem" : "1.6rem")};
      }
    }
    &:hover {
      a {
        color: #25c1a9;
      }
      svg {
        margin-left: 3px;
        margin-right: 13px;
      }
    }
  }
`

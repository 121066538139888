import React, { Fragment } from "react"
import { Button } from "@yarno/thread"
import { useStaticQuery, graphql, Link } from "gatsby"
import Container from "../common/Container"
import { Contact, ContactInner } from "./styles"

const SupportContact = () => {
  const { SupportData } = useStaticQuery(
    graphql`
      query {
        SupportData: prismicSupport {
          ...SupportPage
        }
      }
    `
  )
  const {
    support_contact_email,
    support_contact_email_link,
    support_contact_phone,
    support_contact_phone_link
  } = SupportData.data

  return (
    <Contact>
      <Container>
        <ContactInner>
          {support_contact_phone && (
            <a href={support_contact_phone_link || "#"}>
              <i className="fa fa-phone" />
              {support_contact_phone}
            </a>
          )}
          {support_contact_email && (
            <a href={support_contact_email_link || "#"}>
              <i className="fa fa-envelope" />
              {support_contact_email}
            </a>
          )}
          <Link to="/support/contact">
            <Button secondary label="Contact us" />
          </Link>
        </ContactInner>
      </Container>
    </Contact>
  )
}
export default SupportContact
